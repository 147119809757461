@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

$dark-color: #001362;

.waiting-banner {
  background-color: $dark-color;
  padding: utils.rem(103) utils.bu(10.5);
  border-radius: utils.rem(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: map-get(colors.$primary-color, white);
}
