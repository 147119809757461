@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.register {

  .checkbox {
    align-self: baseline;
    margin-left: utils.bu(1);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__login-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get(colors.$text-color, black);
    @include mixins.set-font-family('SemplicitaPro');

    > a {
      margin-left: utils.bu(.5);
      font-size: utils.rem(12);
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__links {
    font-weight: 400;
    color: rgba(map-get(colors.$text-color, black), .87);
    @include mixins.set-font-family('SemplicitaPro');

    a {
      font-size: utils.rem(12);
      color: map-get(colors.$primary-color, primary);
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  .form-action {
    margin-left: auto;
    margin-right: auto;
    width: utils.rem(168);
    margin-bottom: utils.rem(35);
  }
}
