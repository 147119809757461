@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.trending-section {
  width: 100%;
  margin-top: utils.rem(90);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-top: utils.rem(120);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    margin-top: utils.rem(185);
  }

  > header {
    margin-bottom: utils.rem(45);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: utils.rem(160);
    }
  }

  &__title {
    font-size: utils.rem(24);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: utils.rem(-.5);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(36);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      font-size: utils.rem(52);
    }
  }

  &__cards {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: utils.rem(70);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      gap: utils.rem(30);
    }
  }

  &__actions {
    margin-top: utils.rem(45);
    display: flex;
    align-items: center;
    justify-content: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: utils.rem(65);
    }
  }
}
