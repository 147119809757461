@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.basic-select {
  width: 100%;
  $block: &;

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 utils.rem(1000) map-get(colors.$primary-color, white) inset !important;
  }

  &__label {
    color: map-get(colors.$text-color, black);
    padding-left: utils.bu(1);
    @include mixins.set-font-family('SemplicitaPro');

    &-wrapper {
      display: flex;
    }

    &-prefix {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__icon {
    cursor: pointer;
    color: map-get(colors.$primary-color, primary);
    font-weight: 350;
    padding-right: utils.rem(15);
  }

  &__select-wrapper {
    cursor: pointer;
    @include mixins.input-container;

    &:focus-within {
      border-bottom: utils.rem(1) solid map-get(colors.$primary-color, secondary);
    }
  }

  select {
    background: url("../../icons/chevron-down-icon.svg") no-repeat calc(100% - .5rem) calc(100% - .5rem);
    cursor: pointer;
    @include mixins.input-content;
  }

  &__error {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    color: map-get(colors.$text-color, error);
    margin-top: utils.bu(.5);
    padding-left: utils.bu(1);

    > p {
      cursor: pointer;
      margin-top: utils.bu(.5);
      margin-left: auto;
      font-size: utils.rem(14);
      color: map-get(colors.$primary-color, primary);
    }
  }

  &--error {
    #{$block}__select-wrapper {
      border-bottom: utils.rem(1) solid map-get(colors.$text-color, error);
    }
  }

  &--shadowed {
    #{$block}__select-wrapper {
      box-shadow: 0 utils.rem(2) utils.rem(4) rgb(0 0 0 / 12%);
      padding: utils.rem(15);
      border-radius: utils.rem(5);

      input {
        border: none;
      }
    }
  }

  &--guttered {
    #{$block}__select-wrapper {
      margin-left: utils.bu(4);
    }
  }

  &--disabled {
    #{$block}__icon {
      cursor: auto;
    }
  }

  &--borderless {

    #{$block}__select-wrapper {
      border: none;
    }
  }

  &--selectable {

    select {
      cursor: pointer;
    }
  }
}
