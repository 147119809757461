@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.pill {
  width: utils.rem(168);
  height: utils.rem(36);
  border-radius: utils.rem(30);
  background-color: map-get(colors.$primary-color, white);
  border: utils.rem(1) solid map-get(colors.$primary-color, primary);
  color: map-get(colors.$primary-color, primary);
  padding: utils.bu(2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  svg {
    position: absolute;
    right: utils.bu(1);
    color: map-get(colors.$primary-color, primary);
  }

  &:hover {
    background-color: map-get(colors.$primary-color, primary);
    color: map-get(colors.$primary-color, white);
  }

  &__name {
    font-size: utils.rem(14);
    font-weight: 590;
  }

  &--selected {
    background-color: map-get(colors.$primary-color, primary);
    color: map-get(colors.$primary-color, white);
  }

  &--fitted {
    width: fit-content + utils.bu(4);
  }
}
