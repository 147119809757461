@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.entry-overview {

  > h2 {
    text-transform: uppercase;
    font-size: utils.rem(52);
    font-weight: 700;
  }

  .event-description {
    margin-top: utils.bu(5);
  }

  &__reference {
    margin-top: utils.bu(3);
    font-weight: 600;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__class-selection {
    margin-bottom: utils.bu(10);

    p {
      margin-bottom: utils.bu(.5);
    }

    small {
      color: map-get(colors.$primary-color, primary);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__member-selection,
  &__class-selection {

    > p {
      font-weight: 600;
      margin-bottom: utils.bu(4);
      @include mixins.set-font-family('SemplicitaPro');
    }

    .table__body td:first-child,
    .table__header th:first-child {
      padding-left: 0;
    }
  }

  &__total-due-amount,
  &__entrant-total {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: utils.rem(22);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(362);
      padding: utils.rem(28) utils.rem(42) utils.rem(15);
      justify-content: space-between;
      margin-left: auto;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: utils.bu(2) 0;

      span {
        font-weight: 600;
        margin-right: utils.rem(96);
      }

      p {
        text-align: right;
      }
    }
  }

  &__entrants {
    margin-top: utils.bu(3);

    > p:first-child {
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');
    }

    .accordion {
      margin-bottom: utils.rem(100);
    }
  }

  .accordion__content--open {
    padding: utils.rem(24) utils.rem(20);
  }

  &__actions {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: utils.bu(10);
    margin-bottom: utils.bu(10);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: 0;
      flex-direction: row;
    }

    > .button + .button {
      margin-bottom: utils.bu(2);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-top: 0;
      }
    }
  }
}
