@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.event-description {

  > h2 {
    width: fit-content;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: utils.bu(5);
  }

  > h4 {
    font-weight: 510;
    margin-bottom: utils.bu(5);
  }

  > p {
    font-size: utils.rem(20);
    font-weight: 400;
    @include mixins.set-font-family('SemplicitaPro');
  }
}
