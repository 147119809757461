@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.complete-profile {

  &__title {
    font-weight: 400;
    color: map-get(colors.$text-color, rich-black);
    margin-bottom: utils.bu(5);
  }

  &__subtitle {
    margin-bottom: utils.bu(1);
    color: rgba(map-get(colors.$primary-color, black), .87);
    font-weight: 400;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__content {
    margin-top: utils.rem(71);
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
    }
  }

  &__form {
    flex: 2;
  }

  &__context {
    flex: .25;
    align-items: center;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-right: utils.rem(60);
      align-items: unset;
    }
  }

  &__avatar {
    position: relative;
    height: utils.rem(234);
    background-color: map-get(colors.$primary-color, white);
    border-radius: utils.rem(5);
    padding: utils.rem(31) utils.rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 utils.rem(4) utils.rem(2) rgba(map-get(colors.$text-color, secondary), .2);
    margin-bottom: utils.bu(5);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: 0;
    }
  }

  &__upload-action {
    display: flex;
    position: absolute;
    cursor: pointer;
    bottom: utils.rem(30);
    right: utils.rem(60);
    color: map-get(colors.$primary-color, primary);
  }
}
