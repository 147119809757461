@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.sport-search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    flex-direction: row;
  }

  &__details {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 2 1 auto;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(577);
    }
  }

  &__image {
    flex: 1 1 auto;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-left: utils.rem(78);
    }

    & > img {
      width: utils.rem(339);
      height: utils.rem(268);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        width: utils.rem(488);
        height: utils.rem(387);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        width: unset;
        height: unset;
      }
    }
  }

  &__title {
    width: utils.rem(285);
    margin-top: utils.rem(45);
    text-transform: uppercase;
    text-align: center;
    font-size: utils.rem(36);
    line-height: utils.rem(72);
    font-weight: 700;
    letter-spacing: utils.rem(-.5);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.rem(95);
      width: utils.rem(659);
      font-size: utils.rem(48);
      white-space: nowrap;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: 0;
      width: utils.rem(577);
      font-size: utils.rem(72);
      line-height: unset;
      white-space: unset;
    }
  }

  &__sports {
    width: utils.rem(341);
    display: flex;
    justify-content: center;
    margin-top: utils.rem(45);
    gap: utils.rem(5);
    flex-wrap: wrap;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(554);
      margin-top: utils.rem(65);
      gap: utils.rem(25);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(600);
    }

    .button {
      width: utils.rem(168);
    }
  }

  &__search {
    width: utils.rem(340);
    margin-top: utils.rem(25);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(406);
      margin-top: utils.rem(65);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(466);
      margin-top: utils.rem(35);
    }

    .search-input {
      border-radius: utils.rem(30);
      box-shadow: 0 utils.rem(1) utils.rem(9) rgba(map-get(colors.$primary-color, black), .12);
      background-color: map-get(colors.$primary-color, white);
    }

    .search-input__wrapper {
      background-color: map-get(colors.$primary-color, white);

      input {
        background-color: map-get(colors.$primary-color, white);
      }
    }
  }
}
