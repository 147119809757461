@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.public-event-card {
  width: utils.rem(325);
  height: utils.rem(504);
  border-radius: utils.rem(10);
  background-color: map-get(colors.$primary-color, white);
  border: utils.rem(1) solid rgba(map-get(colors.$background-color, profile-image), .3);
  cursor: pointer;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    width: utils.rem(390);
    height: utils.rem(510);
  }

  &__image {
    width: utils.rem(325);
    height: utils.rem(332);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(390);
      height: utils.rem(336);
    }

    > svg,
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: utils.rem(10);
      border-top-right-radius: utils.rem(10);
    }
  }

  &__description {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__details {
    height: utils.rem(95);
    padding: utils.rem(15) utils.rem(24);

    small {
      color: rgba(map-get(colors.$text-color, tuna), .8);
      font-weight: 400;
    }
  }

  &__event-name {
    display: flex;
    align-items: center;
    font-weight: 510;
    font-size: utils.rem(14);
    justify-content: space-between;
    color: rgba(map-get(colors.$text-color, tuna), .8);
    margin-bottom: utils.rem(10);
  }

  &__action {
    border-top: utils.rem(.5) solid map-get(colors.$border-color, card);
    height: utils.rem(79);
    padding: utils.rem(31) utils.rem(24);
    display: flex;
    align-items: center;

    .button {
      padding: 0;
      text-align: left;
      font-weight: 700;
    }
  }
}
