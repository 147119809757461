@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.profile-details {
  $block: &;

  &__title {
    font-weight: 400;
    color: map-get(colors.$text-color, rich-black);
    margin-bottom: utils.bu(5);
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
    }
  }

  &__form {
    flex: 2;
  }

  &__context {
    align-items: center;
    flex: .25;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-right: utils.rem(60);
      align-items: unset;
    }
  }

  &__tabs {
    margin-top: utils.bu(1.5);

    .tab {
      color: map-get(colors.$border-color, input);
      font-weight: 400;
      width: 100%;

      &__container {
        width: 100%;
      }
    }

    .tab--active {
      background-color: rgba(map-get(colors.$primary-color, primary), .12);
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__avatar {
    position: relative;
    height: utils.rem(234);
    background-color: map-get(colors.$primary-color, white);
    border-radius: utils.rem(5);
    padding: utils.rem(31) utils.rem(50);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 utils.rem(4) utils.rem(2) rgba(map-get(colors.$text-color, secondary), .2);
  }

  &__upload-action {
    display: flex;
    position: absolute;
    cursor: pointer;
    bottom: utils.rem(30);
    right: utils.rem(60);
    color: map-get(colors.$text-color, secondary);
  }

  &--editing {

    #{$block}__upload-action {
      color: map-get(colors.$primary-color, primary);
    }
  }
}
