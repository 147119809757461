@import "~@neslotech/eventhub-ui-kit/src/stylesheets/app";

.app-root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* width */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(77, 112, 255, .12);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba(77, 112, 255, .25);
  border-radius: 3px;
}
