@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.list-events {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  margin-top: utils.rem(90);
  margin-bottom: utils.rem(90);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-top: utils.rem(120);
    margin-bottom: utils.rem(120);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    flex-direction: row;
    margin-top: utils.rem(185);
    margin-bottom: utils.rem(185);
  }

  &__title {
    width: utils.rem(260);
    margin-top: utils.rem(90);
    text-transform: uppercase;
    font-size: utils.rem(36);
    line-height: utils.rem(72);
    letter-spacing: utils.rem(-.5);
    text-align: center;
    font-weight: 700;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(663);
      margin-top: utils.rem(95);
      font-size: utils.rem(48);
      white-space: nowrap;
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(587);
      margin-top: 0;
      font-size: utils.rem(72);
      line-height: unset;
      white-space: unset;
    }
  }

  &__description {
    font-weight: 510;
    width: 100%;
    text-align: center;
    margin-top: utils.rem(65);
    @include mixins.set-font-family('SemplicitaPro');

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      width: utils.rem(587);
    }
  }

  &__action {
    margin-top: utils.rem(45);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: utils.rem(65);
    }
  }

  &__details {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 2 1 auto;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(577);
    }
  }

  &__image {
    display: flex;
    flex: 1 1 auto;
    gap: utils.rem(22);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      gap: utils.rem(33);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-left: utils.rem(145);
    }

    &-split {
      display: flex;
      flex-direction: column;
      gap: utils.rem(13);

      & > img {
        width: utils.rem(158);
        height: utils.rem(158);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          width: utils.rem(230);
          height: utils.rem(230);
        }

        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          width: unset;
          height: unset;
        }
      }

      &:nth-of-type(2) {

        & > img {
          width: utils.rem(158);
          height: 100%;

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            width: utils.rem(230);
          }

          @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
            width: unset;
            height: unset;
          }
        }
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        gap: utils.rem(19);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        gap: utils.rem(25);
      }
    }
  }
}
