@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.crew-members-mobile {
  margin-top: utils.bu(5);
  margin-bottom: utils.bu(5);

  > header {

    > h4 {
      font-weight: 510;
      margin-bottom: utils.rem(43);
    }

    > p {
      font-weight: 400;
      margin-bottom: utils.rem(25);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__form {

    .accordion > .accordion__content--open {
      padding: utils.bu(3);
      background-color: map-get(colors.$primary-color, white);

      .form .form-row > p {
        color: map-get(colors.$primary-color, primary);
        @include mixins.set-font-family('SemplicitaPro');
      }
    }
  }

  &__actions {
    border-top: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .1);
    background-color: map-get(colors.$primary-color, white);
    padding: utils.rem(19) utils.rem(50);
  }
}
