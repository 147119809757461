@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.contact-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: utils.rem(250);
  background-color: map-get(colors.$primary-color, white);
  border-radius: utils.rem(10);
  box-shadow: 0 utils.rem(4) utils.rem(12) utils.rem(1) rgba(map-get(colors.$primary-color, black), .07);

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(496);
  }

  &__details {
    height: utils.rem(170);
    display: flex;
    align-items: center;
    padding: utils.rem(52) utils.rem(52) utils.rem(42);
  }

  &__icon {
    min-width: utils.rem(70);
    height: utils.rem(68);
    background-color: map-get(colors.$primary-color, primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: map-get(colors.$primary-color, white);
    }
  }

  &__detail {
    margin-left: utils.rem(42);

    > p {
      font-weight: 400;
      margin-bottom: utils.rem(10);
      @include mixins.set-font-family('SemplicitaPro');
    }

    > small {
      color: map-get(colors.$text-color, dark-gray);
      font-size: utils.rem(14);
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__action {
    display: flex;
    align-items: center;
    min-height: utils.rem(80);
    max-height: utils.rem(80);
    padding: utils.rem(8) utils.rem(8) utils.rem(8) utils.rem(51);
    border-top: utils.rem(.5) solid map-get(colors.$border-color, card);

    > .button {
      padding: 0;
      text-align: start;
      font-weight: 700;
      letter-spacing: utils.rem(1.25);
    }
  }

  & + & {

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-left: utils.rem(37);
    }
  }
}

.contact-us {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.rem(35);
  width: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    gap: utils.rem(50);
  }

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    flex-direction: row;
    gap: unset;
  }
}
