@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.service-offerings {

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: utils.rem(150);

    > img {
      width: 100%;

      @include mixins.breakpoint(large) {
        width: unset;
      }
    }
  }

  > main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: utils.bu(6);
  }

  > footer {
    margin-top: utils.rem(140);
  }
}

.offering-card {
  padding: utils.bu(5.5) utils.bu(8);
  background-color: map-get(colors.$primary-color, white);
  border: utils.rem(1) solid rgba(map-get(colors.$background-color, profile-image), .3);
  border-radius: utils.rem(10);
  max-width: utils.rem(630);

  > header {
    width: utils.rem(106);
    height: utils.rem(103);
    border-radius: 50%;
    background-color: map-get(colors.$primary-color, primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get(colors.$primary-color, white);
    margin-bottom: utils.bu(5);
  }

  dt {
    margin-left: 0;
    margin-bottom: utils.bu(4);
    font-size: utils.rem(34);
    font-weight: 400;
  }

  dd {
    color: map-get(colors.$text-color, dark-gray);
    margin-left: 0;
    font-weight: 400;
    font-size: utils.rem(18);
    @include mixins.set-font-family('SemplicitaPro');
  }
}
