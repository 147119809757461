@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.sport-selection {
  max-width: utils.rem(1110);

  &__title {
    font-weight: 400;
    color: map-get(colors.$text-color, rich-black);
    margin-bottom: utils.bu(5);
  }

  &__subtitle {
    color: rgba(map-get(colors.$primary-color, black), .87);
    font-weight: 400;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__content {
    margin-top: utils.rem(71);
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
    }
  }

  &__selections {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: utils.bu(3.5) utils.bu(.5);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      gap: utils.bu(3);
      padding: utils.bu(3.5);
      justify-content: left;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: utils.bu(3.5);
    padding: utils.bu(5) utils.bu(8.5);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
      gap: 0;
    }
  }
}
