@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.profile-form {

  .details-section {

    &__header {

      svg {
        align-self: flex-start;
        color: map-get(colors.$primary-color, primary);
      }
    }

    .card {
      padding: utils.rem(60) utils.rem(45);
    }

    .form-row:first-of-type {
      margin-top: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: utils.bu(5);

    > .button + .button {
      margin-top: utils.bu(2);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      flex-direction: row;
      justify-content: space-between;
      padding-left: utils.rem(57);
      padding-right: utils.rem(57);

      > .button + .button {
        margin-top: 0;
      }
    }
  }

  &--editing {

    .details-section__header {

      svg {
        color: map-get(colors.$text-color, secondary);
      }
    }
  }
}
