@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.entrant-details {

  &__pills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: utils.bu(2);
  }

  > h2 {
    text-transform: uppercase;
    font-size: utils.rem(52);
    font-weight: 700;
  }

  .event-description {
    margin-top: utils.bu(5);
  }

  > .card {
    background-color: transparent;
    margin-top: utils.rem(30);
    box-shadow: none;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      background-color: map-get(colors.$primary-color, white);
      padding: utils.rem(62) utils.rem(148) utils.rem(62) utils.rem(74);
      box-shadow: 0 utils.rem(4) utils.rem(2) rgba(171, 171, 171, .2);
    }
  }

  .details-section {

    &__header {

      svg {
        color: map-get(colors.$primary-color, primary);
      }
    }

    .card {
      padding: utils.rem(57) utils.rem(27);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        padding-left: utils.rem(45);
        padding-right: utils.rem(45);
        padding-bottom: utils.rem(60);
      }
    }
  }

  &__agreements {
    margin-top: utils.rem(100);

    .details-section {

      .card {
        padding: utils.rem(40) utils.rem(27);
        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          padding: utils.rem(50);
        }

        .checkbox__indicator {
          min-width: utils.rem(18);
          min-height: utils.rem(18);
        }

        .checkbox__label {
          font-weight: 400;
          font-size: utils.rem(14);
          @include mixins.set-font-family('SemplicitaPro');
        }

        .checkbox + .checkbox {
          margin-top: utils.rem(50);
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: utils.rem(60);
    margin-bottom: utils.rem(60);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: utils.bu(10);
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }

  .my-details-section {
    margin-top: utils.rem(100);

    &--editMode {

      & > .details-section > .details-section__header > .details-section__message {

        > svg {
          color: rgba(map-get(colors.$text-color, secondary), .87);
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      justify-content: flex-end;
      margin-top: utils.bu(5);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        flex-direction: row;
        margin-left: utils.rem(44);
        margin-right: utils.rem(44);
      }

      .button {
        margin-top: utils.bu(2);
        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          margin-top: 0;
        }
      }
    }
  }

  .participant-details-section {
    margin-top: utils.rem(100);

    &__actions {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin-top: utils.bu(5);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        flex-direction: row;
      }

      .button {
        margin-top: utils.bu(2);
        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          margin-top: 0;
        }
      }
    }
  }
}
