@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.event-details {
  width: 100%;
  padding: utils.rem(25);
  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    display: flex;
    padding: utils.rem(130) utils.rem(65);
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: utils.bu(5);

    > p {
      margin-top: utils.bu(2);
      color: map-get(colors.$primary-color, blue);
    }
  }

  &__context {
    margin-left: auto;
    margin-right: auto;
    margin-top: utils.bu(5);
    border-radius: utils.rem(10);
    min-height: utils.rem(320);
    max-width: utils.rem(396);
    box-shadow: 0 utils.rem(4) utils.rem(2) rgba(171, 171, 171, .2);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: 0;
      min-height: utils.rem(366);
      width: utils.rem(338);
    }

    > img {
      border-top-left-radius: utils.rem(10);
      border-top-right-radius: utils.rem(10);
      height: 100%;
      width: 100%;
      object-fit: cover;

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-top: 0;
        height: utils.rem(273);
      }
    }

    footer {
      background-color: rgba(map-get(colors.$background-color, cultured), .2);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > .button {
        margin-top: utils.rem(30);
      }

      > article {
        margin-top: utils.bu(5);
        padding: utils.rem(14);
        display: flex;
        color: map-get(colors.$primary-color, primary);
        align-items: baseline;

        > *:first-child {
          width: 40%;
          margin-right: utils.bu(2);
        }

        > p {
          margin-top: utils.bu(2);
        }
      }
    }
  }

  &__detail {
    flex: 2;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-left: utils.rem(76);
    }
  }

  &__logos {
    display: flex;
    width: 100%;
    gap: utils.bu(2.5);
    margin-top: utils.bu(5);
    margin-bottom: utils.bu(5);

    > img {
      object-fit: contain;
      max-width: utils.rem(140);
      height: utils.rem(33);
    }
  }

  &__documents {
    margin-top: utils.bu(2.5);
    margin-bottom: utils.bu(6);
    gap: utils.bu(2.5);
    display: flex;
    flex-direction: column;
  }

  &__classes {
    width: 100%;
    margin-top: utils.rem(55);

    > h5 {
      font-weight: 510;
      margin-bottom: utils.rem(30);
    }

    .table__pagination {
      margin: 0;

      .pagination {
        justify-content: center;
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table__pagination {
        margin: auto;

        .pagination {
          justify-content: flex-end;
        }
      }
    }
  }

  &__attending {
    width: 100%;
    margin-top: utils.bu(10);

    > h5 {
      font-weight: 510;
      margin-bottom: utils.rem(20);
    }

    .search-input {
      margin-bottom: utils.rem(20);

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        width: utils.rem(466);
      }
    }

    .table__pagination {
      margin: 0;

      .pagination {
        justify-content: center;
      }
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table__pagination {
        margin: auto;

        .pagination {
          justify-content: flex-end;
        }
      }
    }
  }
}
