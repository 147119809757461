@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.public-filter-layout {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &__filter-item {
    display: flex;
    align-items: center;
    padding-left: utils.rem(30);

    > svg {
      color: map-get(colors.$primary-color, primary);
    }
  }

  &__filters {
    display: none;
    left: 0;
    top: utils.rem(100);
    position: fixed;
    height: 100%;
    width: utils.rem(350);
    border-right: utils.rem(2) solid rgba(map-get(colors.$background-color, profile-image), .5);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      display: block;
    }

    > header {
      display: flex;
      align-items: center;
      background-color: map-get(colors.$primary-color, primary);
      height: utils.rem(74);
      padding: utils.rem(24) utils.rem(65);
      color: map-get(colors.$primary-color, white);
      @include mixins.set-font-family('SemplicitaPro');

      > svg {
        color: map-get(colors.$primary-color, white);
      }

      > h6 {
        font-weight: 600;
        margin-left: utils.bu(1);
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: utils.rem(59);
    }

    .accordion {
      margin-bottom: 0;
      border-radius: 0;
      border-bottom: utils.rem(2) solid rgba(map-get(colors.$background-color, profile-image), .5);
      background-color: map-get(colors.$background-color, primary);

      &__header {
        background-color: map-get(colors.$background-color, primary);
        height: utils.rem(88);

        &--open {

          svg:first-child {
            rotate: -90deg;
          }
        }
      }

      &--alternate {

        .accordion__header {
          background-color: map-get(colors.$background-color, primary);
        }
      }
    }
  }

  &__content {
    margin-bottom: utils.rem(65);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: utils.rem(100);
      margin-left: utils.rem(465);
      margin-right: utils.rem(117);
      align-items: unset;
      justify-content: unset;
    }

    > header {
      width: 100%;
      padding: utils.rem(44) utils.rem(25) utils.rem(100);
      justify-content: flex-start;
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        padding-left: 0;
        padding-right: 0;
      }

      * {
        max-width: utils.rem(466);
      }
    }
  }

  &__filter-title {
    font-weight: 600;
    margin-left: utils.bu(1);
  }

  &__filter-detail {
    padding: utils.rem(35) utils.rem(65);
    display: flex;
    flex-direction: column;
    gap: utils.rem(20);

    .checkbox__label {
      font-weight: 400;
      color: map-get(colors.$primary-color, black);
      font-size: utils.rem(16);
    }
  }
}
