@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.privacy-policy {
  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    margin-top: utils.rem(-145);
  }

  > p {
    font-weight: 510;
    margin-bottom: utils.bu(5);
  }

  > li {
    margin-bottom: utils.rem(18);
    list-style: disc;
  }

  &__title {
    font-size: utils.rem(16);
    letter-spacing: utils.rem(-.5);
    font-weight: 700;
    color: rgba(map-get(colors.$primary-color, black), .87);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(24);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      font-size: utils.rem(36);
    }
  }

  &__terms {
    margin-left: utils.rem(10);

    > li {
      margin-bottom: utils.rem(18);
      list-style: disc;
    }
  }

  &__text {
    margin-left: utils.rem(15);
  }
}
