@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.public-layout {
  width: 100%;
  background-color: map-get(colors.$background-color, primary);
  $block: &;

  &--white {
    background-color: map-get(colors.$primary-color, white);
  }

  &__username {
    margin-left: utils.bu(1);
    align-self: center;
    justify-self: center;
    font-weight: 510;
  }

  &__content {
    min-height: 100vh;
    margin-top: utils.rem(100);
    padding: utils.rem(50) utils.rem(25);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      padding: utils.rem(120) utils.rem(80);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      padding: utils.rem(50) utils.rem(179);
    }
  }

  &--grey {
    background-color: map-get(colors.$background-color, primary);

    #{$block}__content {
      background-color: map-get(colors.$background-color, primary);
    }
  }

  &--wide {

    #{$block}__content {
      padding: utils.rem(50) utils.rem(25);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        padding: utils.rem(120) utils.rem(60);
      }

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        padding: utils.rem(50) utils.rem(65);
      }
    }
  }

  &--full {

    #{$block}__content {
      padding: 0;
    }
  }

  > footer {
    padding: utils.bu(2);
    position: relative;
    z-index: 1;
    background-color: map-get(colors.$primary-color, primary);
    display: flex;
    flex-direction: column;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      height: utils.rem(315);
      padding: 0;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
      padding-top: utils.rem(72);
      padding-left: utils.rem(65);
      padding-right: utils.rem(125);
    }

    > a {
      margin-top: utils.rem(25);
      color: map-get(colors.$primary-color, white);
    }

    > section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50vw;
      flex-direction: column;

      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        flex-direction: row;
      }

      & > * {

        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          order: 3;
        }

        &:nth-child(4) {
          order: 4;
        }

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {

          &:nth-child(1) {
            order: 1;
          }

          &:nth-child(2) {
            order: 2;
          }

          &:nth-child(3) {
            order: 3;
          }

          &:nth-child(4) {
            order: 4;
          }
        }
      }

      & > svg {
        margin-top: utils.rem(40);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-left: auto;
          width: unset;
          margin-top: 0;
        }
      }

      > ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: utils.rem(25) 0;
        color: map-get(colors.$primary-color, white);
        gap: utils.rem(25);

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          flex-direction: row;
          gap: unset;
        }

        li {

          @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
            margin-right: utils.rem(15);
          }
        }
      }
    }

    > #{$block}__social {
      margin-top: utils.rem(45);
      flex-direction: row;
      gap: utils.rem(36);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        margin-top: utils.rem(25);
        gap: unset;
      }

      li {

        @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
          margin-left: utils.rem(38);
        }
      }
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: utils.rem(20);
    margin-bottom: utils.rem(50);
    color: map-get(colors.$primary-color, white);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      margin-top: utils.rem(60);
      margin-bottom: 0;
    }

    & > small {
      font-size: utils.rem(9);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        font-size: utils.rem(14);
      }
    }
  }

  .dropdown__popper--open {
    z-index: 1000;
  }

  .header {

    .button-group > .button--secondary {
      color: map-get(colors.$text-color, rich-black);
      font-weight: 400;
      width: fit-content;

      @include mixins.breakpoint(large) {
        width: utils.rem(168);
      }
    }
  }
}
