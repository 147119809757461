@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.auth-layout {
  width: 100%;
  height: 100vh;
  background: url("../../../images/auth-background.png") no-repeat;
  background-size: cover;
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: utils.bu(8.5);
  }

  &__logo {
    display: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: block;
    }

    &--alt {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: auto;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: utils.rem(80);
    background-color: map-get(colors.$primary-color, white);
    padding: utils.rem(50) utils.rem(45);
    width: 100%;
    margin-top: auto;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      justify-content: flex-start;
      height: utils.rem(835);
      padding: utils.rem(65) utils.rem(210) utils.rem(65) utils.rem(210);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      justify-content: center;
      margin-top: unset;
      padding: utils.rem(50) utils.bu(11);
      width: utils.rem(522);
      border-radius: utils.rem(20);
      min-height: utils.rem(636);
      max-height: utils.rem(702);
    }

    & > header {
      width: 100%;

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        width: unset;
      }
    }

    > main {
      width: 100%;
    }
  }

  &__title {
    font-weight: 400;
    color: rgba(map-get(colors.$primary-color, black), .87);
    margin-bottom: utils.rem(30);
    text-align: left;
    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      text-align: center;
    }

    &--alt {
      letter-spacing: utils.rem(.4);
      white-space: nowrap;
      margin-bottom: utils.rem(30);

      @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
        display: none;
      }
    }
  }

  &__subtitle {
    display: none;

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      display: block;
      text-align: center;
      margin-top: utils.bu(3);
      margin-bottom: utils.rem(30);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }
}
