@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.entry-wizard {
  padding-left: utils.rem(25);
  padding-right: utils.rem(25);
  width: 100%;
  height: 100%;
  @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
    display: flex;
    padding: 0 utils.rem(65) utils.rem(100) utils.rem(330);
  }

  &__steps {
    margin-top: utils.rem(200);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: static;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: 0;
      display: block;
      width: utils.rem(260);
      padding-top: utils.rem(50);
      padding-left: utils.rem(45);
      justify-content: unset;
      align-items: unset;
      left: 0;
      position: fixed;
    }
  }

  &__content {
    margin-top: utils.rem(50);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-left: utils.bu(5);
    }
  }

  &__step {
    cursor: pointer;
    color: map-get(colors.$primary-color, primary);
    display: flex;
    align-items: center;
    opacity: .5;
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: utils.rem(98);
    }

    > p {
      margin-left: utils.rem(35);
    }

    &--active {
      color: map-get(colors.$primary-color, primary);
      opacity: 1;
    }

    & + & {
      margin-left: utils.rem(25);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-left: 0;
      }
    }
  }

  &__bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get(colors.$primary-color, primary);
    width: utils.rem(42);
    height: utils.rem(42);
    border-radius: 50%;
    color: map-get(colors.$primary-color, white);
    font-size: utils.rem(20);
    font-weight: 500;
    @include mixins.set-font-family('SemplicitaPro');
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      width: utils.rem(60);
      height: utils.rem(60);
    }
  }
}
