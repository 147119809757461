@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.class-selection {

  > h2 {
    text-transform: uppercase;
    font-size: utils.rem(52);
    font-weight: 700;
  }

  .event-description {
    margin-top: utils.bu(5);
  }

  &__table {
    margin-top: utils.bu(2);

    > h4 {
      font-weight: 510;
      margin-bottom: utils.bu(3);
    }
  }

  &__forms {
    margin-top: utils.rem(100);
    margin-bottom: utils.rem(77);

    > h4 {
      font-weight: 510;
      margin-bottom: utils.bu(5);
    }
  }

  .accordion__content--open {
    padding: utils.rem(60) utils.rem(55);
  }

  &__actions {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: utils.bu(10);
    margin-bottom: utils.bu(10);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: 0;
      flex-direction: row;
    }

    > .button + .button {
      margin-bottom: utils.bu(2);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-top: 0;
      }
    }
  }
}
