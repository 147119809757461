@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.entry-forms {

  > h2 {
    text-transform: uppercase;
    font-size: utils.rem(52);
    font-weight: 700;
  }

  .event-description {
    margin-top: utils.bu(5);
  }

  &__table {
    margin-top: utils.bu(2);

    > p {
      font-weight: 400;
      margin-bottom: utils.bu(3);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  .table-cell {

    & > svg {
      color: map-get(colors.$primary-color, white);
    }
  }

  &__forms {
    margin-top: utils.rem(100);
    margin-bottom: utils.rem(77);

    > h4 {
      font-weight: 510;
      margin-bottom: utils.bu(5);
    }

    .accordion {
      background-color: transparent;

      &__content {

        &:not(#{&}--open) {

          * {
            height: 0;
            margin: 0;
            @include mixins.transition(height);
          }
        }

        &--open {
          background-color: transparent;
          padding: 0;
        }
      }
    }

    .crew-members {
      margin-top: utils.rem(100);

      > h4 {
        font-weight: 510;
        margin-bottom: utils.rem(43);
      }

      > p {
        font-weight: 400;
        margin-bottom: utils.rem(25);
        @include mixins.set-font-family('SemplicitaPro');
      }

      .table-row {

        &:hover {

          svg {
            color: map-get(colors.$primary-color, primary);
          }
        }
      }

      &__table {
        overflow-x: scroll;

        .table {
          overflow-x: scroll;

          &__header {
            background-color: map-get(colors.$primary-color, primary);
            color: map-get(colors.$primary-color, white);
            @include mixins.set-font-family('SemplicitaPro');

            th {
              min-width: utils.rem(160);
              max-width: utils.rem(160);
              @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
                min-width: fit-content;
              }
            }

            th:first-child {
              border-top-left-radius: utils.rem(5);
              border-bottom-left-radius: utils.rem(5);
              width: utils.rem(10);
              min-width: utils.rem(10);
              max-width: utils.rem(10);

              @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
                min-width: fit-content;
              }
            }

            th:last-child {
              border-top-right-radius: utils.rem(5);
              border-bottom-right-radius: utils.rem(5);
            }
          }

          &__body {

            td {
              min-width: utils.rem(160);
              max-width: utils.rem(160);
              @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
                min-width: fit-content;
              }

              input {
                width: 100%;
              }

              &:first-child {
                width: utils.rem(10);
                min-width: utils.rem(10);
                max-width: utils.rem(10);
                padding-left: utils.rem(20);

                @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
                  min-width: fit-content;
                }
              }

              &:last-child {
                padding-right: utils.rem(20);
              }
            }
          }
        }
      }

      &__actions {
        border-top: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .1);
        background-color: map-get(colors.$primary-color, white);
        padding: utils.rem(19) utils.rem(50);
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: utils.bu(10);
    margin-bottom: utils.bu(10);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-bottom: 0;
      flex-direction: row;
    }

    > .button + .button {
      margin-bottom: utils.bu(2);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-top: 0;
      }
    }
  }
}
