@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.welcome-banner {
  position: fixed;
  top: utils.rem(138);
  display: flex;
  left: 0;
  padding: utils.bu(2);
  align-items: center;
  justify-content: center;
  background-color: map-get(colors.$primary-color, primary);
  box-shadow: 0 utils.rem(-4) utils.rem(12) utils.rem(2) rgba(map-get(colors.$primary-color, black), .1);
  color: map-get(colors.$primary-color, white);
  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    width: utils.rem(956);
    height: utils.rem(69);
    padding: utils.rem(23) utils.rem(85);
    margin-left: utils.rem(230);
  }
  @include mixins.breakpoint(map-get(config.$breakpoints, x-large)) {
    margin-left: utils.rem(400);
  }

  &__message {
    margin-left: utils.bu(2);
    font-weight: 510;
  }
}
