@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.section-layout {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: utils.rem(24);
    text-transform: uppercase;
    letter-spacing: utils.rem(-.5);
    font-weight: 700;
    color: rgba(map-get(colors.$primary-color, black), .87);

    @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
      font-size: utils.rem(36);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      font-size: utils.rem(52);
    }
  }

  &__content {
    margin-top: utils.rem(45);
    margin-bottom: utils.rem(45);

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      margin-top: utils.rem(185);
      margin-bottom: utils.rem(185);
    }
  }
}
