@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.sports-information {

  &__form {

    &--default {

      h6 {
        @include mixins.set-font-family('SemplicitaPro');
      }
    }

    .add-action {
      margin-top: utils.bu(3.5);
    }
  }
}
