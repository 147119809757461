@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.sports-details {

  > header {
    display: flex;
    flex-direction: column;

    @include mixins.svg-hover();

    > section {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    margin-left: utils.bu(2);
  }

  &__subtitle {
    margin-top: utils.bu(5);
  }

  &__selections {
    margin-top: utils.bu(5);

    .card {
      gap: utils.bu(3);
      display: flex;
      flex-wrap: wrap;

      > p {
        text-align: center;
        width: 100%;
        @include mixins.set-font-family('SemplicitaPro');
      }
    }
  }

  &__actions {
    margin-top: utils.bu(4);
  }
}
