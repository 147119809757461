@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.faq-card {
  display: flex;
  flex-direction: column;
  width: utils.rem(602);
  background-color: map-get(colors.$primary-color, white);
  border-radius: utils.rem(10);
  border: utils.rem(1) solid rgba(196, 196, 196, .3);
  margin-bottom: utils.rem(50);
  height: 100%;

  @include mixins.breakpoint(map-get(config.$breakpoints, medium)) {
    margin-right: utils.rem(50);
    height: utils.rem(499);

    &:nth-child(2n) {
      margin-right: utils.rem(0);
    }
  }

  &__details {
    margin-left: utils.rem(50);
    margin-bottom: utils.rem(48);
    margin-top: utils.rem(43);
  }

  &__icon {
    width: utils.rem(106);
    height: utils.rem(103);
    background-color: map-get(colors.$primary-color, primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: utils.rem(40);

    svg {
      color: map-get(colors.$primary-color, white);
    }
  }

  &__title {
    font-size: utils.rem(34);
  }

  &__question {
    font-size: utils.rem(18);
    margin-top: utils.rem(30);
    margin-bottom: utils.rem(30);
    color: #808080;
    cursor: pointer;

    &--clicked {
      color: map-get(colors.$primary-color, primary);
      text-decoration-line: underline;
    }

    &:hover {
      color: map-get(colors.$primary-color, primary);
      text-decoration-line: underline;
    }
  }

  &__answer {
    font-size: utils.rem(18);
    line-height: utils.rem(26);
    color: map-get(colors.$primary-color, primary);
    margin-right: utils.rem(15);
  }
}

.faqs {
  width: 100%;

  &__title {

    & > span {
      font-size: utils.rem(52);
      font-weight: 700;
      margin-bottom: utils.rem(37);
    }

    & > p {
      margin-bottom: utils.rem(110);
      margin-top: utils.rem(37);
      font-size: utils.rem(20);
      line-height: utils.rem(24);
      font-weight: 400;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
