@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.payment-method {

  > h2 {
    text-transform: uppercase;
    font-size: utils.rem(52);
    font-weight: 700;
  }

  .event-description {
    margin-top: utils.bu(5);
  }

  > h4 {
    font-weight: 274;
  }

  &__reference {
    margin-top: utils.bu(3);
    margin-bottom: utils.bu(5);
    font-weight: 600;
    @include mixins.set-font-family('SemplicitaPro');
  }

  &__methods {
    margin-top: utils.rem(54);

    .tabs {
      overflow-x: scroll;
    }

    .tab--active {
      color: map-get(colors.$primary-color, primary);
      background-color: rgba(map-get(colors.$primary-color, primary), .12);
    }

    > p {
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__upload {
    display: flex;
    align-items: center;
    flex-direction: column;

    > p {
      font-size: utils.rem(14);
      font-weight: 400;
      margin-bottom: utils.bu(2);
    }
  }

  &__details {
    height: fit-content;
    margin-top: utils.bu(5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: utils.rem(30);
    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
      flex-direction: row;
      margin-bottom: 0;
    }

    > * {
      flex: 1 auto;
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        width: 50%;
        flex-direction: row;
      }
    }

    > * + * {
      margin-top: utils.rem(20);
      @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
        margin-left: utils.rem(20);
      }
    }

    .eft-payment,
    .payfast-payment,
    .ozow-payment {
      font-size: utils.rem(18);
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');

      > header {
        margin-bottom: utils.bu(3);
      }

      > main {

        > p {
          margin-bottom: utils.bu(2);
        }

        li {
          margin-bottom: utils.bu(1);
        }

        span {
          font-weight: 600;
        }
      }

      &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: utils.bu(5);
        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          align-items: unset;
          justify-content: unset;
        }
      }
    }

    .card-payment {
      font-size: utils.rem(18);
      font-weight: 400;
      @include mixins.set-font-family('SemplicitaPro');

      &__types {
        display: flex;
        align-items: center;
      }

      &__type {
        display: flex;
        align-items: center;
        justify-content: center;
        height: utils.rem(37);
        width: utils.rem(91);
        padding: utils.rem(10) utils.rem(20);
        border-radius: utils.rem(5);
        border: utils.rem(1) solid map-get(colors.$text-color, secondary);
        margin-right: utils.rem(12);
        @include mixins.svg-hover();
      }
    }

    .accordion {
      background-color: transparent;
      box-shadow: none;

      &__content--open {
        height: fit-content;
        background-color: map-get(colors.$primary-color, white);
        padding: utils.rem(30);
        @include mixins.breakpoint(map-get(config.$breakpoints, large)) {
          padding: utils.rem(25) utils.rem(75) utils.rem(60) utils.rem(35);
        }
      }
    }

    .entry-summary {
      height: fit-content;
      background-color: map-get(colors.$primary-color, white);
      padding: utils.rem(81) utils.rem(34) utils.rem(28);

      > h4 {
        margin-bottom: utils.bu(4);
        font-weight: 510;
      }

      > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        margin-bottom: utils.rem(19);
        @include mixins.set-font-family('SemplicitaPro');
      }

      > ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: utils.rem(19);
        @include mixins.set-font-family('SemplicitaPro');
      }

      &__total-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: utils.rem(19);
        @include mixins.set-font-family('SemplicitaPro');

        > span:first-child {
          font-weight: 600;
        }
      }

      > footer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: utils.bu(5);
  }
}
