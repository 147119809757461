@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.empty-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: utils.rem(5);
  padding-top: utils.rem(92);
  padding-bottom: utils.rem(60);

  > h1 {
    text-transform: uppercase;
    font-size: utils.rem(34);
    letter-spacing: utils.rem(-.5);
    font-weight: 700;
  }

  > p {
    text-align: center;
    width: utils.rem(375);
    margin-top: utils.bu(4);
    margin-bottom: utils.bu(5);
    line-height: utils.rem(24);
  }

  > .button {
    margin: auto;
    width: utils.rem(232);
  }
}
